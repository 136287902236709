import React from 'react';
import {
	ThankYouPageContainer,
	ThankYouPageContentContainer,
	ThankYouModal,
	HeaderContainer,
	StyledHeader,
	StyledParagraph,
	StyledCheckMark, StyledImage
} from './styled';
import { Link } from 'gatsby';
import MHGC from '../../assets/images/mhgc.png';

const ThankYouPage = ( ) => {
	return (
		<ThankYouPageContainer>
			<ThankYouPageContentContainer>
				<ThankYouModal>
					<HeaderContainer>
						<StyledHeader>
                    Kiitos viestistäsi!
						</StyledHeader>
						<StyledCheckMark/>
						<StyledParagraph>
                  Palaamme asiaan mahdollisimman pian!
						</StyledParagraph>
					</HeaderContainer>
					<Link to={'/'}>
						<StyledImage src={MHGC} alt={''}/>
					</Link>
				</ThankYouModal>
			</ThankYouPageContentContainer>
		</ThankYouPageContainer>
	);
};

export default ThankYouPage;
