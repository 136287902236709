import styled, {keyframes} from 'styled-components';

const CheckMarkAnimationRight = keyframes`
		0% {
			height: 0;
		}
		100% {
			height: 100px;
		}
`;

const CheckMarkAnimationLeft = keyframes`
		0% {
			height: 0;
		}
		100% {
			height: 55px;
		}
`;

const ThankYouPageContainer = styled.div`
	position: relative;
	min-height: calc(100vh - 62px - (12px * 4 * 2));
	overflow: hidden;
	padding: 48px 0;

	@media only screen and (min-width: 426px){
		min-height: calc(100vh - 62px - (12px * 7 * 2));
		padding: 84px 0;
	}
`;

const ThankYouPageContentContainer = styled.section`
	margin: auto;
	max-width: 1040px;
	padding: 0 12px;
`;

const ThankYouModal = styled.div`
	max-width: 940px;
	width: 100%;
	text-align: center;
	padding: 36px 24px 24px;
	color: #005899;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,.17);
	border-radius: 3px;
	background: #f2f2f2;
	height: 100%;
	margin: 20px auto 0;
	transition: all .2s ease-in;
	box-sizing: border-box;

	@media only screen and (min-width: 426px){
		padding: 36px 36px 72px;
	}

	@media only screen and (min-width: 769px){
		padding: 72px 72px 84px;
	}
`;

const HeaderContainer = styled.header`
	padding: 0 24px 48px;
	text-align: center;
	line-height: 1;
	max-width: 730px;
	margin: 0 auto;

	@media only screen and (min-width: 426px) {
		padding-bottom: 24px;
	}

	@media only screen and (min-width: 769px) {
		padding-bottom: 40px;
	}
`;

const StyledHeader = styled.h3`
	margin: 0;
	color: #074368;
	padding: 0 24px;
`;

const StyledParagraph = styled.p`
	font-family: scout thin,roboto,helvetica,arial,sans-serif;
	text-align: center;
	max-width: 500px;
	margin: 24px auto;
	font-size: 22px;
	font-weight: 400;
	line-height: 1.3;
	padding: 20px 24px 0;
	border-top: 1px solid #005899;
`;

const StyledCheckMark = styled.span`
	position: relative;
	top: 2px;
	display: inline-block;
	min-width: 100px;
	width: 100px;
	height: 100px;
	margin-top: 30px;
	background-color: transparent;
	border: 2px solid #074368;
	z-index: 1;
	border-radius: 50%;

	&::before {
		content: '';
		position: absolute;
		transform-origin: left top;
		box-sizing: border-box;
		width: 4.1px;
		animation: ${CheckMarkAnimationRight} .3s ease-out 200ms;
		animation-fill-mode: forwards;
		top: 78.08px;
		left: 45.74px;
		transform: rotate(-135deg);
		background-color: #074368;
	}

	&::after {
		content: '';
		position: absolute;
		transform-origin: left top;
		box-sizing: border-box;
		width: 4.1px;
		animation: ${CheckMarkAnimationLeft} .2s ease-in;
		animation-fill-mode: forwards;
		top: 38.18px;
		left: 5.7px;
		transform: rotate(-45deg);
		background-color: #074368;
	}
`;

const StyledImage = styled.img`
	width: 35%;
`;

export {
	ThankYouPageContainer,
	ThankYouPageContentContainer,
	ThankYouModal,
	HeaderContainer,
	StyledHeader,
	StyledParagraph,
	StyledCheckMark,
	StyledImage
};
