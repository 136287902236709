import React from 'react';
import ThankYouPage from '../components/ThankYouPage';

const ThankYou = () => {
	return (
		<ThankYouPage/>
	);
};

export default ThankYou;
